
import { defineComponent, onMounted } from "vue";
import KTModalCard from "@/components/cards/Card.vue";
import KTViewUsersModal from "@/components/modals/general/ViewUsersModal.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "ViewUsers",
  components: {
    KTModalCard,
    KTViewUsersModal,
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("View Users", ["Modals", "General"]);
    });
  },
});
